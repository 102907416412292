/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-width: 320px) and (max-width: 380px) {
  .text-title {
    font-size: 28px;
  }
  .list-product {
    font-size: 12px;
  }

  .wrapper-product {
    margin-bottom: 30px;
  }

  .wrapper-product__head {
    font-size: 18px;
    line-height: 20px;
    text-align: center;
  }

  .input-numberphone {
    margin-top: 20px;
  }

  .button {
    font-size: 20px;
  }

  .error-text {
    font-size: 14px;
    letter-spacing: -0.4px;
    padding-left: 25px;
  }
  .text-success {
    font-size: 18px;
  }
  .text-title {
    font-size: 24px;
  }
}

/* iPads (portrait and landscape) ----------- */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .text-success {
    font-size: 28px;
  }
}

header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.container {
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

.out-wrapper {
  display: flex;
  justify-content: center;
  flex: 1;
  background-color: var(--hbl-dark-green);
}

.wrapper {
  margin: 0 auto;
  max-width: 450px;
  width: 100%;
}

.image-logo {
  width: 200px;
  margin: 0 auto;
  padding-top: 16px;
  padding-bottom: 32px;
}

.wrapper-product {
  border-radius: 8px;
  margin-inline: 20px;
  margin-bottom: 40px;
}

.wrapper-product.error {
  margin-bottom: 8px !important;
}

.wrapper-product .ant-select .ant-select-selector {
  min-height: 50px;
  padding-left: 12px;
  padding-block: 6px;
  border-radius: 45px;
}

.wrapper-product .ant-select-multiple.ant-select-lg .ant-select-selection-item {
  border-radius: 45px;
}

.list-product {
  padding-bottom: 8px;
  margin-top: 10px;
  list-style: none;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  max-height: 130px;
  /* min-height: 100px; */
  overflow-y: auto;
}

.item-product {
  padding-inline: 20px;
  line-height: 20px;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: -0.3px;
  color: #8b888f;
  cursor: pointer;
  padding-block: 4px;
}
.item-product.check {
  background: #8ce36c;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.button {
  background: var(--hbl-light-green);
  color: #fff;
  border-radius: 45px;
  border: none;
  width: calc(100% - 40px);
  margin-inline: 20px;
  line-height: 46px;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}
.button.disabled {
  background: #8b888f;
}
.input-numberphone {
  background: #ffffff;
  box-shadow: 0px 2px 5px 2px rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  border: none;
  font-weight: 400;
  font-size: 18px;
  line-height: 50px;
  letter-spacing: -0.3px;
  width: calc(100% - 40px);
  margin: 0 20px;
  margin-top: 40px;
  color: rgba(0, 0, 0, 0.8);
  padding-left: 20px;
  border-radius: 45px;
}

.input-numberphone:focus {
  border: none;
  outline: none;
}

.error-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.3px;
  padding-inline: 30px;
  padding-block: 10px;
  color: var(--hbl-white);
}

.text-title,
.text-success,
.name-text {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: -0.3px;
  text-align: center;
  text-transform: uppercase;
  color: var(--hbl-white);
  margin-block: 17px;
  font-weight: 700;
}
.gif-success {
  margin: 0 auto;
  width: 56%;
}
.text-success {
  font-weight: 700;
  font-size: 20px;
}
.text-title {
  font-weight: 700;
  font-size: 30px;
  text-transform: none;
  line-height: 28px;
  color: var(--hbl-white);
  padding-top: 30px;
}
.wrapper-product__head .text-head {
  background: #ffffff;
  box-shadow: 0px 2px 5px 2px rgb(0 0 0 / 15%);
  border-radius: 6px;
  line-height: 52px;
  padding-left: 20px;
  padding-right: 20px;
  color: #8b888f;
}
.text-head.single {
  width: 100%;
  background: rgb(255, 255, 255);
  line-height: 24px;
  border-radius: 6px;
  padding: 12px 8px;
  color: rgb(139, 136, 143);
}
.text-head.multiple {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.item-product img,
.text-head.multiple img {
  width: 12px;
  height: 10px;
}

/* Info success*/
.wrapper-info {
  text-align: center;
  color: var(--hbl-white);
}
.wrapper-info .info-member {
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: -0.3px;

  text-transform: uppercase;
  color: var(--hbl-white);
}
.wrapper-info .check-product-member {
  padding-block: 12px;
  text-transform: uppercase;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --hbl-dark-green: #266431;
  --hbl-light-green: #42a046;
  --hbl-white: #fff;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
}
